import React from 'react'

const ForgotPassowrd = () => {
  return (
    <div>
      Forgot Password
    </div>
  )
}

export default ForgotPassowrd