import React from "react";
import CategoryList from "../components/CategoryList";
import BannerProduct from "../components/BannerProduct";
import HorizontalCardProduct from "../components/HorizontalCardProduct";
import VerticalCardProduct from "../components/VerticalCardProduct";

const Home = () => {
  return (
    <div>
      <CategoryList />
      <BannerProduct />

      <HorizontalCardProduct category={"airpodes"} heading={"Top's Sweets"} />
      <HorizontalCardProduct
        category={"watches"}
        heading={"Popular's Sweets"}
      />

      <VerticalCardProduct category={"mobiles"} heading={"Namkeens"} />
      <VerticalCardProduct category={"Mouse"} heading={"Dry Fruit Sweets"} />
    </div>
  );
};

export default Home;
